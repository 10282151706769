import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { handlerUrls } from 'mocks/handlers';
import {
  CommonRequest,
  CommonResponse,
  Method,
  ServiceName,
  StatusCode,
  SuccessOrNot,
} from 'models/common/RestApi';
import { CommonJwtResponse } from 'models/vars/common/Common';
import useSessionStore from 'stores/useSessionStore';
import useAuthStore from 'stores/useAuthStore';
import mem from 'mem';
import { authLogOut } from 'apis/vars/login/Login';

const API_LOGIN = '/api/login';

const REFRESH_URL = `${process.env.REACT_APP_API_BASE_URL}${API_LOGIN}/re-issuance`;

const LOGIN_API = `${API_LOGIN}/login`;
const LOGIN_DEV_API = `${API_LOGIN}/login-dev`;
const LOGOUT_API = `${API_LOGIN}/logout`;

const IGNORE_REGEX = /^\/api\/login\//;
// const IGNORE_REGEX = /^(?!\/api\/login).*/;

const ARR_IGNORE_API = [
  // LOGIN_API,
  // LOGOUT_API,
  '/api/common/access-info',
  '/api/common/sap-if-cop',
  '/api/member/sign-up',
  '/api/common/search-manager?searchEmail=',
  '/api/member/valid-vendor?userId=',
  '/api/member/reset',
  '/api/member/valid-joint-certificate',
  '/v1/commonHqCodes/hq',
  // `${API_LOGIN}/process`,
  // `${API_LOGIN}/valid-mail-auth`,
  // `${API_LOGIN}/complete`,
  // '/api/common/files',
];

const getRefreshToken = mem(
  async (): Promise<string | void> => {
    try {
      if (useAuthStore.getState().accessCode == 'INTERNAL') {
        const {
          data: { accessToken, refreshToken },
        } = await axios.get(REFRESH_URL, {
          headers: {
            Authorization: `Bearer ${useAuthStore.getState().accessToken}`,
          },
        });
        useAuthStore.setState({ accessToken: accessToken });
        return accessToken;
      } else {
        const {
          data: { accessToken, refreshToken },
        } = await axios.get(REFRESH_URL);
        useAuthStore.setState({ accessToken: accessToken });
        return accessToken;
      }
    } catch (e) {
      console.warn(e);
      alert('refresh token is expired');
      authLogOut();
      window.location.assign('/'); // TODO: 서버확인 후 수정필요
    }
  },
  { maxAge: 2000 }
);

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    config.headers['x-language'] = useSessionStore.getState().langCd;
    config.headers['accept-language'] = useSessionStore.getState().langCd;

    // if (!ARR_IGNORE_API.includes(config.url!) && !IGNORE_REGEX.test(config.url!)) {
    if (
      ARR_IGNORE_API.filter((url) => config.url?.startsWith(url)).length === 0 &&
      !IGNORE_REGEX.test(config.url!)
    ) {
      // if (!IGNORE_REGEX.test(config.url!)) {
      if (!useAuthStore.getState().accessToken) {
        alert('token is null!');
        window.location.assign('/'); // TODO: 서버확인 후 수정필요
      }
      config.headers['Authorization'] = `Bearer ${useAuthStore.getState().accessToken}`;

      // console.log('확인해봅시당~', config.url);
    }
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response: any): Promise<any> => {
    if (response.config.url == LOGIN_API || response.config.url == LOGIN_DEV_API) {
      if (response.headers.authorization && response.headers.authorization.startsWith('Bearer ')) {
        const accessToken = response.headers.authorization.substring(7);
        useAuthStore.setState({ accessToken: accessToken });
      }
    }

    if (response.config.url == '/api/member') {
      if (response.headers.authorization && response.headers.authorization.startsWith('Bearer ')) {
        const accessToken = response.headers.authorization.substring(7);
        useAuthStore.setState({ accessToken: accessToken });
        useSessionStore.setState({ userId: response.data.data });
        console.log(response.data);
      }
    }

    if (response.config.url == LOGOUT_API) {
      useAuthStore.setState({ accessToken: '' });
      useSessionStore.getState().resetSession();
      sessionStorage.clear();
      window.location.assign('/'); // TODO: 서버확인 후 수정필요
    }

    if (response.data instanceof Blob) {
      const commonFileResponse: CommonJwtResponse<Blob> = {
        successOrNot: SuccessOrNot.Y,
        statusCode: StatusCode.SUCCESS,
        data: new Blob([response?.data]),
      };

      return commonFileResponse;
    }
    const commonResponse: CommonJwtResponse = response.data as CommonJwtResponse;
    if (commonResponse.statusCode && commonResponse.statusCode === StatusCode.SESSION_EXPIRE) {
      alert('Session expired!');
      sessionStorage.clear();
      window.location.assign('/'); // TODO: 서버확인 후 수정필요
    } else if (
      commonResponse.statusCode &&
      commonResponse.statusCode === StatusCode.NOT_AUTHORIZED_EXCEPTION
    ) {
      window.location.assign('/*');
    }
    return commonResponse;
  },

  async (error: any): Promise<any> => {
    const {
      config,
      response: { status },
    } = error;

    if (status == 500) {
      // alert('서버 에러');
      console.error('status 500 :: ', error);
      //window.location.assign('/');
      return;
    }

    if (status == 400) {
      console.log('400 error :: ', error);
    }

    if (error.response.data.errorCode == 'DUPLICATE_LOGIN') {
      alert('중복로그인 발생!');
      authLogOut();
      window.location.assign('/');
      return;
    }

    if (status == 403) {
      console.error('403 에러');
      alert('login token is expired');
      authLogOut();
      window.location.assign('/');
    }

    if (config.url === REFRESH_URL || status !== 401 || config.sent) {
      return Promise.reject(error);
    }

    config.sent = true;
    const accessToken = await getRefreshToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return axios(config);
    }

    return Promise.reject(error);
  }
);

const getConfig = (request: CommonRequest): any => {
  let baseURL = '';
  if (process.env.REACT_APP_MSW_ENABLE === 'N' || handlerUrls.indexOf(request.url) === -1) {
    switch (request.serviceName) {
      case ServiceName.YOUR_BACK_END_SERVICE_NAME:
        baseURL = `${process.env.REACT_APP_API_BASE_URL}`;
        break;
      default:
    }
  }

  let config = {};
  switch (request.method) {
    case Method.GET:
      config = {
        baseURL: baseURL,
        //timeout: 6000,
        headers: request?.headers || {
          Accept: 'application/json',
        },
        params: request?.queryParams || {},
        responseType: request?.responseType || 'json',
      };
      break;
    case Method.DELETE:
      config = {
        baseURL: baseURL,
        timeout: 2000,
        headers: request?.headers || {
          Accept: 'application/json',
        },
        params: request?.queryParams || {},
        responseType: request?.responseType || 'json',
      };
      break;
    case Method.POST:
    case Method.PUT:
    case Method.PATCH:
      config = {
        baseURL: baseURL,
        timeout: 90000,
        headers: request?.headers || {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        responseType: request?.responseType || 'json',
      };
      break;
    default:
  }
  return config;
};

export const callJwtApi = async (apiRequest: CommonRequest): Promise<CommonJwtResponse> => {
  let response: CommonJwtResponse = {
    successOrNot: SuccessOrNot.N,
    statusCode: StatusCode.BAD_REQUEST_ERROR,
    data: {},
  };

  switch (apiRequest.method) {
    case Method.GET:
      response = await axios.get(apiRequest.url, getConfig(apiRequest));
      break;
    case Method.POST:
      response = await axios.post(
        apiRequest.url,
        apiRequest.bodyParams || {},
        getConfig(apiRequest)
      );
      break;
    case Method.PUT:
      response = await axios.put(
        apiRequest.url,
        apiRequest.bodyParams || {},
        getConfig(apiRequest)
      );
      break;
    case Method.DELETE:
      response = await axios.delete(apiRequest.url, getConfig(apiRequest));
      break;
    case Method.PATCH:
      response = await axios.patch(
        apiRequest.url,
        apiRequest.bodyParams || {},
        getConfig(apiRequest)
      );
      break;
    default:
      break;
  }

  // kck ins
  // if (response.successOrNot === 'N' && typeof response.data === 'string') {
  //   const msg: string = (response?.data || '') as string;
  //   throw new Error(msg);
  // }

  return response;
};
