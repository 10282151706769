import React from 'react';
import styled from '@emotion/styled';
import useAuthStore from 'stores/useAuthStore';

const MaintenancePage: React.FC = () => {
  const { accessCode } = useAuthStore();

  return (
    <Container>
      <Content>
        <Title>
          시스템 작업에 따른 VARS(지급계좌등록시스템) 지급(예정)내역조회 기능 중단 안내 / Notice of
          VARS(Vendor Account Registration System) system blocking of payment (scheduled) list check
          function due to work on the system
        </Title>
        {accessCode === 'EXTERNAL' && (
          <Message1>
            시스템 작업으로 인해 VARS의 지급(예정)내역조회 기능이 중단되었습니다. <br /> 작업을 마친
            후 해당 기능을 다시 제공해드릴 예정이오니 참고 부탁드립니다. <br />
            (지급(예정)내역조회 기능을 다시 제공드릴 수 있는 시점이 확정되면 본 페이지에서 추가로
            안내드리겠습니다.) <br />
            <br /> Due to work on the VARS system, payment (scheduled) list check function is
            blocked. <br /> Please note that we will provide this function again after the work is
            finished. <br />
            (When the time that we can provide the payment (scheduled) list check function again is
            confirmed, we will inform you about the time on this page.)
          </Message1>
        )}
        {accessCode === 'INTERNAL' && (
          <Message1>
            시스템 작업으로 인해 VARS의 지급(예정)내역조회 기능이 중단되었습니다. <br /> 작업을 마친
            후 해당 기능을 다시 제공해드릴 예정이오니 참고 부탁드립니다. <br />
            (지급(예정)내역조회 기능을 다시 제공드릴 수 있는 시점이 확정되면 본 페이지에서 추가로
            안내드리겠습니다.) <br />
            <br /> Due to work on the VARS system, payment (scheduled) list check function is
            blocked. <br /> Please note that we will provide this function again after the work is
            finished. <br />
            (When the time that we can provide the payment (scheduled) list check function again is
            confirmed, we will inform you about the time on this page.)
          </Message1>
        )}
        {/* <Message2>[시스템 중단 일정]</Message2>
        <Message3>
          24/12/30 (월) 19:00 ~ 25/01/02 (목) 06:00 [한국시간 기준, GMT+9] <br />→ 24/12/30 (월)
          05:00 ~ 25/01/01 (수) 16:00 [미국 동부 기준, GMT-5] <br /> → 24/12/30 (월) 04:00 ~
          25/01/01 (수) 15:00 [미국 중부 기준, GMT-6] <br /> → 24/12/30 (월) 11:00 ~ 25/01/01 (수)
          22:00 [중앙 유럽 기준, GMT+1] <br /> → 24/12/30 (월) 18:00 ~ 25/01/02 (목) 05:00 [중국
          표준시, GMT+8] <br /> → 24/12/30 (월) 17:00 ~ 25/01/02 (목) 04:00 [인도네시아 서부 기준,
          GMT+7]
        </Message3>
        <br />
        <Message2>[문의처]</Message2>
        <Message3>
          - LG 에너지솔루션 : 업무혁신.경영관리PI.경영관리／HR PI팀 진민하 (minha.jin@lgensol.com)
          <br /> - LG CNS : 화학/전지경영정보DX팀 전형준 (brotherjun@lgespartner.com)
        </Message3>

        <Message1>
          --------------------------------------------------------------------------------------------------------------------{' '}
          <br />
          {accessCode == 'EXTERNAL' && (
            <Message1>
              Due to work on the VARS system, the VARS system is shut down and all functions are
              blocked at the following time.
            </Message1>
          )}
          {accessCode == 'INTERNAL' && (
            <Message1>
              Due to preparation for ERP Open, the VARS system is shut down and all functions are
              blocked at the following time.
            </Message1>
          )}
        </Message1>
        <Message2>[Schedule of system outages]</Message2>
        <Message3>
          24/12/30(Mon.) 19:00 ~ 25/01/02(Thu.) 06:00[KST, GMT+9] <br />→ [USA(Eastern)
          Timezone(GMT-5) : 24/12/30(Mon.) 05:00 ~ 25/01/01(Wed.) 16:00] <br /> → [USA(Central)
          Timezone(GMT-6) : 24/12/30(Mon.) 04:00 ~ 25/01/01(Wed.) 15:00] <br /> → [Central EU
          Timezone(GMT+1) : 24/12/30(Mon.) 11:00 ~ 25/01/01(Wed.) 22:00] <br /> → [China Standard
          Timezone(GMT+8) : 24/12/30(Mon.) 18:00 ~ 25/01/02(Thu.) 05:00] <br /> → [Indonesia Western
          Timezone(GMT+7) : 24/12/30(Mon.) 17:00 ~ 25/01/02(Thu.) 04:00]
        </Message3>
        <br />
        <Message2>[Contact]</Message2>
        <Message3>
          - LG Energy Solution : Business Management / HR PI Team Minha Jin (minha.jin@lgensol.com)
          <br /> - LG CNS : Chemical/Battery Business Information DX Team Hyeongjun Jeon
          (brotherjun@lgespartner.com)
        </Message3> */}
      </Content>
    </Container>
  );
};

/* MaintenancePage.css */

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  text-align: center;
`;

const Content = styled.div`
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
`;

const Message1 = styled.div`
  font-size: 18px;
  color: #555;
`;

const Message2 = styled.div`
  font-size: 18px;
  color: #555;
  font-weight: bold;
`;

const Message3 = styled.div`
  font-size: 18px;
  color: #007bff; /* 파란색 */
  text-align: center;
`;

export default MaintenancePage;
