// 주민등록번호
export const personalNumber = (number: string | undefined) => {
  if (!number) return '';
  const maskedNumber = number.replace(/(\d{6})(\d{7})/, '$1-*******');
  return maskedNumber;
};

// 날짜(YYYYMMDD > YYYY.MM.DD)
export const formatDate = (params: any) => {
  const dateValue = params.value;
  if (!dateValue) return null;

  const year = dateValue.substring(0, 4);
  const month = dateValue.substring(4, 6);
  const day = dateValue.substring(6, 8);

  return `${year}.${month}.${day}`;
};

// 전화번호
export const phoneNumber = (number: string | undefined) => {
  if (!number) return '';
  // 전화번호의 숫자만 추출
  const digitsOnly = number.replace(/\D/g, '');

  // 전화번호 길이에 따라 포맷팅하여 반환
  if (digitsOnly.length === 11) {
    return digitsOnly.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (digitsOnly.length === 10) {
    return digitsOnly.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  } else if (digitsOnly.length === 9) {
    return digitsOnly.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
  } else {
    // 예외 처리: 길이가 10자리 또는 11자리가 아닌 경우
    return digitsOnly;
  }
};

// 계좌번호
export const accntNumber = (number: string | undefined) => {
  // 계좌번호가 없는 경우 빈 문자열 반환
  if (!number) return '';

  // 계좌번호의 숫자만 추출
  const digitsOnly = number.replace(/\D/g, '');

  // 계좌번호를 포맷팅하여 반환
  const formattedNumber = digitsOnly.replace(/(\d{3})(\d{6})(\d{7})/, '$1-$2-$3');
  return formattedNumber;
};

// // 세자리마다 콤마
// export const addCommas = (number) => {
//   // 숫자를 문자열로 변환
//   number = Number(number);
//   if (number >= 0) {
//     const strNumber = String(number);

//     // 소수점 체크
//     let integerPart, decimalPart;
//     if (strNumber.includes('.')) {
//       [integerPart, decimalPart] = strNumber.split('.');
//     } else {
//       integerPart = strNumber;
//     }

//     // 세 자리마다 쉼표 추가
//     let result = '';
//     for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
//       if (count > 0 && count % 3 === 0) {
//         result = ',' + result;
//       }
//       result = integerPart[i] + result;
//     }

//     // 소수점 이하 부분 추가
//     if (decimalPart !== undefined) {
//       result += '.' + decimalPart;
//     }
//     return result;
//   } else {
//     const formattedNumber = Math.abs(number)
//       .toString()
//       .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

//     return `-${formattedNumber}`;
//   }
// };

export const addCommasFor = (number) => {
  // 숫자로 변환
  number = Number(number);

  // 유효한 숫자인지 확인
  if (isNaN(number)) {
    return ''; // 숫자가 아닌 경우 빈 문자열 반환
  }

  // 소수점 둘째 자리까지 고정
  const strNumber = number.toFixed(2); // 예: "3424.74"

  // 소수점과 정수 부분 분리
  const [integerPart, decimalPart] = strNumber.split('.'); // ["3424", "74"]

  // 세 자리마다 쉼표 추가
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ','); // "3,424"

  // 결과 생성: 정수 부분 + 소수점 부분
  return `${formattedIntegerPart}.${decimalPart}`; // "3,424.74"
};

export const addCommas = (number) => {
  // 숫자를 문자열로 변환
  number = Number(number);

  if (!isNaN(number)) {
    const strNumber = number.toString(); // 숫자를 문자열로 변환

    // 소수점 체크
    let integerPart, decimalPart;
    if (strNumber.includes('.')) {
      [integerPart, decimalPart] = strNumber.split('.');
    } else {
      integerPart = strNumber;
    }

    // 세 자리마다 쉼표 추가
    let result = '';
    for (let i = integerPart.length - 1, count = 0; i >= 0; i--, count++) {
      if (count > 0 && count % 3 === 0) {
        result = ',' + result;
      }
      result = integerPart[i] + result;
    }

    // 소수점 이하 부분 추가
    if (decimalPart !== undefined) {
      result += '.' + decimalPart;
    }

    return result;
  }

  return number.toString(); // 입력이 숫자가 아닌 경우 그대로 반환
};

// export const formatCurrency = (params: { value: number; data: any }) => {
//   const { value, data } = params;
//   const currency = data.curr; // 다른 필드값인 currency를 이용하여 JPY 여부 판단

//   if (currency === 'JPY' || !Number.isInteger(value)) {
//     // JPY거나 값이 정수로 떨어지지 않는 경우 세 자리마다 콤마만 찍어주기
//     return addCommas(value);
//   } else {
//     // JPY가 아니면서 값이 정수로 떨어지는 경우 뒤에 .00을 붙여주기
//     return addCommas(parseFloat(value.toFixed(2)));
//   }
// };
