import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import { GbasicTable, GbasicTableWrap } from 'components/tables/GTable';
import { GLabelAtom } from 'components/atom/label';
import { AlignBox, AlignItem } from 'components/organism/AlignBox';
import { useColumnDefsForFrgnlList } from './hooks/OverseasColumnDefs';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { GContentGrid } from 'components/layouts/GContentGrid';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { GButtonBox } from 'components/layouts/GLayoutBox';
import GTabs from 'components/organism/GTabs';
import Save from 'components/asset/images/Confirm.svg';
import New from 'components/asset/images/Pen.svg';
import EmptyState from 'components/atom/emptyState';
import { phoneNumber } from 'components/vars/common/FormatNumber';
import _ from 'lodash';
import DmsRegFrgnAcntModal from './modal/DmsRegFrgnAcntModal';

import { useColumnHistoryDefs } from './hooks/OverseasHistoryColumnDefs';
import usePifPrint, { PifBankInfo } from './hooks/usePifPrint';
import { useCommonModal } from 'hooks/useCommonModal';
import { useCommonCert } from 'hooks/useCommonCert';
import { usePrintStore } from './hooks/usePrintStore';
import { getAdminManagerListApi, getUserManagerListApi } from 'apis/common/ManagerSearchApi';

import {
  getForeignAccounts,
  getMultiCopCdList,
  requestRegistForeignAccount,
} from 'apis/vars/account/ForeignAccount';

//계좌정보등록이력
import { getDmstFrgAccnHistory, getOvFrgAccnHistory } from 'apis/vars/account/AccountHistoryApi';

import { ForeignAccountVO } from 'models/vars/account/ForeignAccountVO';
import { SampleResponse } from 'models/sample/Sample';
import { VendorReqInfoVO } from 'models/vars/vendor/VendorReqInfoVO';
import CheckBoxVars from 'components/vars/common/CheckBoxVars';
import { getVdcpCdMap } from 'apis/vars/member/Member';
import { MemberVdcpCdMapVO } from 'models/vars/member/MemberVdcpCdMapVO';

interface PropsType {
  vendorInfo: VendorReqInfoVO;
  radioRender: () => void;
}

const OverseasRegistPage = (props: PropsType) => {
  const { t, i18n } = useTranslation();
  const { callCertModule } = useCommonCert();
  const [dmsRegFrgnAcntModalOpen, setDmsRegFrgnAcntModalOpen] = useState<boolean>(false);

  const [vendorInfo, setVendorInfo] = useState<VendorReqInfoVO>({
    ...props.vendorInfo,
  } as VendorReqInfoVO);

  const [isAccnExist, setIsAccnExist] = React.useState('N'); // 디폴트 값이 있다면 1, 없다면 0 (0일때는 )

  const [sampleList, setSampleList] = useState<SampleResponse[]>([]);
  const gridApiRef = useRef<any>(null);

  const [multiCopCdList, setMultiCopCdList] = useState<any[]>([]);

  // 선택된 멀티법인 코드
  const [slctMultiCopCdList, setSlctMultiCopCdList] = useState<any[]>([]);

  // 선택된 멀티법인 obj
  // const [slctMultiCopObjList, setSlctMultiCopObjList] = useState<any[]>([]);

  // PIF
  const { handleClickPif, setBankInfo, setBasicVendorInfo, setPifMultiCopCd, setWktDiv } =
    usePifPrint();

  /**
   * 해외 계좌 목록 조회
   */
  const getFrgnAccns = async () => {
    openLoading(true);
    console.log('vendorInfo ::: ', vendorInfo);
    setBasicVendorInfo(vendorInfo);
    getForeignAccounts(vendorInfo.vdcpCd)
      .then((resp) => {
        if (resp) {
          setFrgnAccounts(resp);
          if (resp.length > 0) {
            setIsAccnExist('Y');
          }
        }
      })
      .finally(() => {
        openLoading(false);
      });
  };

  // 계좌정보등록이력
  const [frgnAccountHistory, setFrgnAccountHistory] = useState<ForeignAccountVO[]>([]);
  const getAccnHist = async () => {
    openLoading(true);
    if (vendorInfo.dmstFrgDivsCd === 'DO') {
      getDmstFrgAccnHistory()
        .then((resp) => {
          if (resp) {
            setFrgnAccountHistory(resp);
          }
        })
        .finally(() => {
          openLoading(false);
        });
    } else {
      getOvFrgAccnHistory()
        .then((resp) => {
          if (resp) {
            setFrgnAccountHistory(resp);
          }
        })
        .finally(() => {
          openLoading(false);
        });
    }
  };

  const { defaultFrgnColDef, colFrgnDefs } = useColumnDefsForFrgnlList({
    callback: getFrgnAccns,
    vendorInfo: vendorInfo,
    isKrw: false,
  });

  const { defaultFrgnColDef1, colFrgnDefs1 } = useColumnHistoryDefs();

  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const { openCommonModal } = useCommonModal();

  const [frgnAccounts, setFrgnAccounts] = useState<ForeignAccountVO[]>([]);
  const [frgnDftAccount, setFrgnDftAccount] = useState<ForeignAccountVO>();

  const [memberVdcpMap, setMemberVdcpMap] = useState<MemberVdcpCdMapVO>({
    oldVdcpCd: '',
    newVdcpCd: '',
  });

  useEffect(() => {
    // 멀티법인인 경우
    if (vendorInfo.multiCopYn == 'Y') {
      getMultiCopCdList(vendorInfo.soprMgrCopCd).then((resp) => {
        if (resp) {
          setMultiCopCdList(resp);
          //초기화
          setSlctMultiCopCdList([resp.filter((o) => o.copCd == vendorInfo.soprMgrCopCd)[0].copCd]);
          // setSlctMultiCopObjList(resp.filter((o) => o.copCd == vendorInfo.soprMgrCopCd));
          setPifMultiCopCd(resp.filter((o) => o.copCd == vendorInfo.soprMgrCopCd));
        }
      });
    }

    getFrgnAccns();
  }, []);

  const [oldVdcpCd, setOldVdcpCd] = useState<string | null>(null);
  const [newVdcpCd, setNewVdcpCd] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchVdcpCdMap = async () => {
      setLoading(true);
      const data = await getVdcpCdMap(vendorInfo.vdcpCd); // vendorInfo.vdcpCd를 이용해 API 호출
      if (data) {
        setOldVdcpCd(data.oldVdcpCd); // oldVdcpCd 업데이트
        setNewVdcpCd(data.newVdcpCd); // newVdcpCd 업데이트

        // t() 함수에서 동적 변수를 넣을 때 값 넣기
        // isLineBreak가 true이면 다국어 개행처리
        openCommonModal({
          title: t('com.msg.vdcpMapTitle', '__벤더코드 변경안내'),
          content: t('com.msg.vdcpMap', {
            newVdcpCd: data.newVdcpCd, // 실제 값으로 넣기
            oldVdcpCd: data.oldVdcpCd, // 실제 값으로 넣기
          }),
          isLineBreak: true,
        });
      }
      setLoading(false);
    };
    fetchVdcpCdMap(); // 컴포넌트가 마운트될 때 호출
  }, []); // []로 설정하여 컴포넌트가 처음 렌더링될 때만 호출

  const handleValidManager = async () => {
    const soprMgrEmal = vendorInfo.soprMgrEmal; // 이메일 속성에 접근
    const response = await getUserManagerListApi(soprMgrEmal); // API 호출

    if (response?.length > 0) {
      // 유효한 매니저가 있으므로 등록 요청 로직 실행
      handleRegReqBtnClick(); // 등록 요청 함수 호출
    } else {
      openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.01022',
          '__LG에너지솔루션 담당자가 퇴사자로 되어 있어 계좌등록요청이 불가합니다. 마이페이지에서 변경 후 계좌등록요청 진행 부탁드립니다'
        ),
      });
      return false; // 등록 요청 중단
    }
  };

  /**
   * 등록요청
   */
  const handleRegReqBtnClick = () => {
    //계좌등록정보 유무
    if (gridApiRef.current.getSelectedRows().length > 0) {
      // 선택정보 확인
      const stcd = gridApiRef.current.getSelectedRows()[0].accnRegProgStatCd;
      let resultTxt;
      if (stcd != 'T') {
        // 임시서장 상태만 요청 가능
        switch (stcd) {
          case 'C':
            resultTxt = t('com.label.00660', '__해당 계좌는 이미 등록 완료된 계좌입니다.');
            break;
          case 'P':
            resultTxt = t('com.label.00677', '__등록 진행중인 계좌입니다.');
            break;
          case 'R':
            resultTxt = t('com.label.00678', '__수정 후 요청이 가능합니다');
            break;
        }
        openCommonModal({
          modalType: 'alert',
          content: resultTxt,
        });
        return;
      }

      if (vendorInfo.vdcpCtryCd.toUpperCase().startsWith('KR')) {
        callCertModule({
          userId: vendorInfo.vdcpCd,
          bznoKey: vendorInfo.bznoKey,
          callbackFn: (resultObject) => {
            gridApiRef.current.getSelectedRows()[0].dgsVal = resultObject.signedData;
            gridApiRef.current.getSelectedRows()[0].copList = slctMultiCopCdList;
            openLoading(true);
            requestRegistForeignAccount(gridApiRef.current.getSelectedRows()[0])
              .then((resp) => {
                if (resp.data.result == 'S') {
                  openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
                  getFrgnAccns();
                  openLoading(false);
                } else {
                  openLoading(false);
                  openCommonModal({
                    modalType: 'alert',
                    content: t('com.label.00466', '__등록요청에 실패했습니다.'),
                  });
                  return;
                }
              })
              .catch((e) => {
                openLoading(false);
              });
          },
        });
      } else {
        openLoading(true);
        gridApiRef.current.getSelectedRows()[0].copList = slctMultiCopCdList;
        requestRegistForeignAccount(gridApiRef.current.getSelectedRows()[0])
          .then((resp) => {
            if (resp.data.result == 'S') {
              openMessageBar('success', t('com.msg.save', '__저장되었습니다.'));
              getFrgnAccns();
              openLoading(false);
            } else {
              openLoading(false);
              openCommonModal({
                modalType: 'alert',
                content: t('com.label.00466', '__등록요청에 실패했습니다.'),
              });
              return;
            }
          })
          .catch((e) => {
            openLoading(false);
          });
      }
    } else {
      openCommonModal({
        modalType: 'alert',
        content: t('com.msg.choRegReqAccn', '__등록 요청할 계좌를 선택하세요'),
      });
    }
  };

  /**
   * 신규 버튼 클릭시
   */
  const handleNewAccntRegBtnClick = () => {
    if (vendorInfo.dmstFrgDivsCd == 'DO') {
      // if (frgnAccounts.length > 0) {
      //   openCommonModal({
      //     modalType: 'alert',
      //     content: '추가로 계좌를 등록할 수 없습니다',
      //   });
      //   return;
      // }
    } else {
      // 해외 사용자 해외계좌 등록 0318
      const isAccnExistFlag = frgnAccounts.filter((o) => o.wktDivsCd == 'N');
      // if (isAccnExistFlag.length > 0) {
      //   const stcd = isAccnExistFlag[0].accnRegProgStatCd;
      //   if (stcd == 'T' || stcd == 'P') {
      //     openCommonModal({
      //       modalType: 'alert',
      //       content: '진행중인 계좌가 있습니다.',
      //     });
      //     return;
      //   }
      // }
    }
    setDmsRegFrgnAcntModalOpen(true);
  };

  // PIF 출력 버튼
  const [isBtnVisible, setIsBtnVisible] = useState<boolean>(false);
  const { setOvAccnId } = usePrintStore();

  useEffect(() => {
    if (gridApiRef.current) {
      gridApiRef.current.addEventListener('selectionChanged', onSelectionChanged);
    }
  }, [gridApiRef.current]);

  const onGridReady = (params: any) => {
    gridApiRef.current = params.api;
    if (gridApiRef.current) {
      gridApiRef.current.addEventListener('selectionChanged', onSelectionChanged);
    }
  };

  const [selectedBankInfo, setSelectedBankInfo] = useState<any>();
  const onSelectionChanged = () => {
    const selectedRows = gridApiRef.current.getSelectedRows();
    const selectedAccnId = String(selectedRows[0]?.accnRegReqId);
    // 그리드에서 선택된 값의 진행상태가 진행중, 반려, 임시저장일 경우
    setSelectedBankInfo(selectedRows[0]);
    setIsBtnVisible(
      selectedRows.length > 0 &&
        (selectedRows[0].accnRegProgStatCd === 'P' ||
          selectedRows[0].accnRegProgStatCd === 'R' ||
          selectedRows[0].accnRegProgStatCd === 'T')
    );
    setWktDiv(selectedRows[0].addAtrValCtn03);
    setOvAccnId(selectedAccnId);
  };

  useEffect(() => {
    //0407 수정
    setBankInfo({ ...selectedBankInfo, copNm: props.vendorInfo.copNm });
  }, [selectedBankInfo]);

  // 국내 외화 계좌 등록화면 엔솔 담당자와 담당자 이메일 (퇴사자 표시를 위함)
  const [managerEmail, setManagerEmail] = useState(''); // 이메일 상태 저장
  const [managerName, setManagerName] = useState(''); // 담당자 이름 상태 저장
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리

  // API 호출 함수
  const fetchManagerEmail = async (email) => {
    try {
      const response = await getUserManagerListApi(email); // API 호출
      if (response?.length > 0) {
        setManagerEmail(email); // 이메일이 있으면 상태에 저장
        setManagerName(vendorInfo.soprMgrNm ?? (t('com.label.00807') || '')); // 담당자 이름 저장
      } else {
        setManagerEmail(t('com.label.00807') || ''); // 없으면 '퇴사자'로 상태 설정
        setManagerName(t('com.label.00807') || ''); // 담당자도 '퇴사자'로 설정
      }
    } catch (error) {
      console.error('API 호출 에러:', error);
      setManagerEmail(t('com.label.00807') || ''); // 오류 발생 시 '퇴사자'로 설정
      setManagerName(t('com.label.00807') || ''); // 담당자도 '퇴사자'로 설정
    } finally {
      setIsLoading(false); // 로딩 완료
    }
  };

  useEffect(() => {
    if (vendorInfo.soprMgrEmal) {
      fetchManagerEmail(vendorInfo.soprMgrEmal); // 이메일로 API 호출
    } else {
      setManagerEmail(t('com.label.00807') || ''); // 이메일 정보가 없으면 '퇴사자'로 표시
      setManagerName(t('com.label.00807') || ''); // 이메일 정보가 없으면 '퇴사자'로 표시
      setIsLoading(false);
    }
  }, [vendorInfo.soprMgrEmal]);

  // 언어 변경 시 컴포넌트 재렌더링
  useEffect(() => {
    const handleLanguageChange = () => {
      setManagerEmail(t('com.label.00807') || ''); // 언어 변경 시 이메일 재설정
      setManagerName(t('com.label.00807') || ''); // 언어 변경 시 이름 재설정
    };

    i18n.on('languageChanged', handleLanguageChange); // 언어 변경 이벤트 리스너 추가

    return () => {
      i18n.off('languageChanged', handleLanguageChange); // 언어 변경 이벤트 리스너 제거
    };
  }, [i18n, t]);

  return (
    <>
      {props.radioRender()}
      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content={t('com.label.00582', '__거래처 기본 정보')} />
        {isBtnVisible && (
          <GButton
            txt={t('com.label.prntPif', '__Payment Information Form 출력')}
            sizes="small"
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
            // chkImg={Delete} 프린터기 이미지 확인필요
            onClick={handleClickPif}
          ></GButton>
        )}
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00080', '상호')}</GLabelAtom>
              </th>
              <td>{props.vendorInfo.vdcpErpNm}</td>
              <th>
                <GLabelAtom align="left">Tax ID</GLabelAtom>
              </th>
              <td>{props.vendorInfo.copRegNo}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00081', '__대표자명')}</GLabelAtom>
              </th>
              <td>{props.vendorInfo.rpsnNm}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00086', '__거래처코드')}</GLabelAtom>
              </th>
              <td>{props.vendorInfo.vdcpCd}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00083', '__주소')}</GLabelAtom>
              </th>
              <td colSpan={3}>
                <AlignBox>
                  {vendorInfo.stras}
                  {/* {vendorInfo.ort01} */}
                  {/* <AlignItem>{vendorInfo.stras}</AlignItem> */}
                </AlignBox>
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00084', '__도시')}</GLabelAtom>
              </th>
              {/* <td>{props.vendorInfo.stras}</td> */}
              <td>{props.vendorInfo.ort01}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00088', '__국가키')}</GLabelAtom>
              </th>
              <td>
                <AlignBox>{vendorInfo.land1}</AlignBox>
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content={t('com.label.00089', '__영업부서 담당자')} />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00012', '__이름')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrNm1}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrEncPhn}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrEmal1}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpMgrFaxNo}</td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox showSubTitle={true} content={t('com.label.00093', '__자금부서 담당자')} />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom isRequired={false} align="left">
                  {t('com.label.00012', '__이름')}
                </GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpFundMgrNm}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00091', '__전화번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpFundMgrEncPhn}</td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpFundMgrEmal}</td>
              <th>
                <GLabelAtom align="left">{t('com.label.00665', '__팩스번호')}</GLabelAtom>
              </th>
              <td>{vendorInfo.vdcpFundMgrFaxNo}</td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          showSubTitle={true}
          content={t('com.label.00015', '__LG에너지솔루션 담당자')}
        />
      </SubTitleBtnArea>
      <GbasicTableWrap isBottomMgn={true}>
        <GbasicTable>
          <colgroup>
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '160px' }} />
            <col style={{ width: 'auto' }} />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00610', '__법인명')}</GLabelAtom>
              </th>
              <td>
                {/* [Dev] 칩안에 들어가는 내용은 디자인과 다른 임의의 내용이 들어가 있습니다. (기존 : C100) */}
                {/* <SingleChipSelect disabled width="240px" height="32px" isChip={true} /> */}
                {/* {vendorInfo.multiCopYn == 'Y' ? (
                  <>
                    &emsp;
                    <CheckBoxVars
                      valueKey="copCd"
                      labelKey="copNm"
                      option={multiCopCdList}
                      defaultValue={vendorInfo.soprMgrCopCd}
                      onChange={(e, checked) => {
                        const arrTmp = slctMultiCopCdList;
                        if (checked) {
                          // 선택한 경우
                          arrTmp.push(e.target.value);
                        } else {
                          _.remove(arrTmp, (o) => o == e.target.value);
                        }
                        setPifMultiCopCd(multiCopCdList.filter((o) => arrTmp.includes(o.copCd)));
                        setSlctMultiCopCdList(_.uniq(arrTmp));
                      }}
                    />
                  </>
                ) : (
                  <>{vendorInfo.copNm}</>
                )} */}
                {/* 중국 멀티법인 임시적으로 사용 x */}
                <>{vendorInfo.copNm}</>
              </td>
              <th>
                <GLabelAtom align="left">{t('com.label.00095', '__담당자')}</GLabelAtom>
              </th>
              <td>
                {/* [Dev] 칩안에 들어가는 내용은 디자인과 다른 임의의 내용이 들어가 있습니다. (기존 : Gwanwoo Kim/Professional/Insurance Team) */}
                {/* <SingleChipSelect disabled width="356px" height="32px" isChip={true} /> */}
                {vendorInfo.soprMgrNm}
              </td>
            </tr>
            <tr>
              <th>
                <GLabelAtom align="left">{t('com.label.00090', '__이메일')}</GLabelAtom>
              </th>
              {/* <td>{vendorInfo.soprMgrEmal}</td> */}
              <td>
                {
                  isLoading
                    ? '로딩 중...' // API 응답이 오기 전에는 로딩 표시
                    : managerEmail // 이메일이 있으면 표시, 없으면 '퇴사자' 표시
                }
              </td>
            </tr>
          </tbody>
        </GbasicTable>
      </GbasicTableWrap>
      <GTabs
        flex={true}
        defaultValue={1}
        title={[t('com.label.00097', '__계좌정보'), t('com.label.00658', '__계좌정보등록이력')]}
        onChange={getAccnHist}
      >
        <>
          <GContentGrid
            type="radio"
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1, minHeight: '249px' }}
          >
            <AgGridReact
              defaultColDef={defaultFrgnColDef}
              columnDefs={colFrgnDefs}
              rowData={frgnAccounts}
              rowSelection={'single'}
              enableCellTextSelection
              // onGridReady={(params) => (gridApiRef.current = params.api)}
              onGridReady={onGridReady}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
          <GButtonLayout marginBottom="8px">
            <GButtonBox>
              <GButton
                chkImg={New}
                txt={t('com.label.new', '__신규')}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleNewAccntRegBtnClick}
              />
              <GButton
                chkImg={Save}
                txt={t('com.label.00371', '__등록요청')}
                sizes="medium"
                btnstyled="contained"
                btnColor="primary"
                hasImg={true}
                hasTxt={true}
                onClick={handleValidManager}
              />
            </GButtonBox>
          </GButtonLayout>
        </>
        <>
          <GContentGrid
            isSortable={false}
            className="ag-theme-alpine"
            style={{ flex: 1, minHeight: '249px' }}
          >
            <AgGridReact
              defaultColDef={defaultFrgnColDef1}
              columnDefs={colFrgnDefs1}
              rowData={frgnAccountHistory}
              rowSelection={'multiple'}
              enableCellTextSelection
              onGridReady={(params) => (gridApiRef.current = params.api)}
              tooltipShowDelay={0}
              noRowsOverlayComponent={EmptyState}
              noRowsOverlayComponentParams={{ type: 'grid' }}
            ></AgGridReact>
          </GContentGrid>
          <div style={{ marginBottom: '8px' }} />
        </>
      </GTabs>
      {dmsRegFrgnAcntModalOpen && (
        <DmsRegFrgnAcntModal
          vendorInfo={vendorInfo}
          open={dmsRegFrgnAcntModalOpen}
          frgnDftAccount={frgnDftAccount}
          isAccnExist={isAccnExist}
          close={(param?: boolean) => {
            setDmsRegFrgnAcntModalOpen(false);
            if (param) {
              getFrgnAccns();
            }
          }}
          isKrw={false}
          save={() => console.log()}
          accounts={frgnAccounts}
        />
      )}
    </>
  );
};

export default OverseasRegistPage;
