import { Route, Routes } from 'react-router-dom';
import ProtectRoute from './ProtectRoute';

import useSessionStore from 'stores/useSessionStore';

import CodeManagementPage from 'pages/admin/CodeManagementPage';
import MenuManagementPage from 'pages/admin/MenuManagementPage';
import { MessageManagementPage } from 'pages/admin/MessageManagementPage';
import RoleEmployeeManagementPage from 'pages/admin/RoleEmployeeManagementPage';
import RoleManagementPage from 'pages/admin/RoleManagementPage';
import DevLoginPage from 'pages/common/DevLoginPage';
import NotFoundPage from 'pages/common/NotFoundPage';
import DropzonSamplePage from 'pages/sample/DropzonSamplePage';
import SampleMainPage from 'pages/sample/SampleMainPage';
import TestPage from 'pages/sample/TestPage';
import SampleSearchSelectComponent from 'pages/sample/SampleSearchSelectComponent';
import SampleComponent from 'pages/sample/SampleComponent';
import UnderConstructionPage from 'pages/common/UnderConstructionPage';
import SampleType1 from 'pages/sample/type1/SampleType1';
import SampleType2 from 'pages/sample/type2/SampleType2';
import TempDevLoginPage from 'pages/vars/devonly/TempDevLoginPage';
import AccountRegistPage from 'pages/vars/accountregist/AccountRegistPage';
import MemberRegistPage from 'pages/vars/member/signup/MemberRegistPage';
import MyPage from 'pages/vars/member/mypage/MyPage';
import ReIssuePasswordPage from 'pages/vars/member/manage/ReIssuePasswordPage';
import MemberDetailPage from 'pages/vars/member/manage/MemberDetailPage';
import FaqListPage from 'pages/vars/faq/FaqListPage';
import FaqRegistPage from 'pages/vars/faq/FaqRegistPage';
import QnaListPage from 'pages/vars/qna/QnaListPage';
import QnaDetailPage from 'pages/vars/qna/QnaDetailPage';
import QnaRegistPage from 'pages/vars/qna/QnaRegistPage';
import SampleType90 from 'pages/sample/type90/SampleType90';
import SamplePage from 'pages/sample/SamplePage';
import EmailLogManagementPage from 'pages/admin/EmailLogManagementPage';
import NoticeManagementPage from 'pages/admin/noticemanagement/NoticeManagementPage';
import NoticeManagementDetailPage from 'pages/admin/noticemanagement/NoticeManagementDetailPage';

//퍼블
import Domestic from 'template/vars/accountregist/Domestic';
import Overseas from 'template/vars/accountregist/Overseas';
import Signup from 'template/vars/signup';
import AdminList from 'template/vars/admin/AdminList';
import AdminDetail from 'template/vars/admin/AdminDetail';
import Mypage from 'template/vars/signup/MyPage';
import QnaDetail from 'template/vars/qna/QnaDetail';
import QnaWrite from 'template/vars/qna/QnaWrite';
import QnaList from 'template/vars/qna/QnaList';
import Abnormal from 'template/vars/abnormal';
import Login from 'template/vars/login';
import LoginPage from 'pages/vars/login/LoginPage';
import MemberListPage from 'pages/vars/member/manage/MemberListPage';
import FaqList from 'template/vars/faq/FaqList';
import FaqWrite from 'template/vars/faq/FaqWrite';
import Payment from 'template/vars/payment';
import ModalPage from 'pages/sample/ModalPage';
import AbnormalMonitorPage from 'pages/vars/abnormal/AbnormalMonitorPage';
import PrintTestPage from 'pages/sample/PrintTestPage';

import PaymentSchedulePage from 'pages/vars/payment/PaymentSchedulePage';
import KPaymentAdminPage from 'pages/vars/paymentAdmin/KPaymentAdminPage';
import OPaymentAdminPage from 'pages/vars/paymentAdmin/OPaymentAdminPage';

import PaymentScheduleOldPage from 'pages/vars/paymentOld/PaymentScheduleOldPage';
import KPaymentAdminOldPage from 'pages/vars/paymentAdminOld/KPaymentAdminOldPage';
import OPaymentAdminOldPage from 'pages/vars/paymentAdminOld/OPaymentAdminOldPage';

import MaintenancePage from 'pages/vars/login/MaintenancePage';

export const MenuUrl = {
  BbsManagementPage: '/system/admin/sample/bbs-sample',
  BbsPostPage: '/system/admin/sample/bbs-sample/:bbmNo',
  BbsEditPage: '/system/admin/sample/bbs-edit',
};

const Router = () => {
  const { userId, teamYn, tldYn } = useSessionStore();
  const appEnv = `${process.env.REACT_APP_NODE_ENV}`;
  return (
    <Routes>
      {/* 메인 S */}
      <Route path="/" element={<LoginPage />} />
      {/* <Route path="/" element={<MaintenancePage />} /> */}

      {/* 메인 E */}

      {/* 로그인 진입용 S */}
      <Route path="/business/login" element={<LoginPage />} />
      {/* 로그인 진입용 E */}

      {/* 샘플 S */}
      <Route path="/sample" element={<SampleMainPage />} />
      <Route path="/sample/sample" element={<SamplePage />} />
      <Route path="/sample/modal" element={<ModalPage />} />
      <Route path="/sample/dropzone" element={<DropzonSamplePage />} />
      <Route path="/sample/test-page" element={<TestPage />} />
      <Route path="/sample/type1" element={<SampleType1 />} />
      <Route path="/sample/type2" element={<SampleType2 />} />
      <Route path="/sample/type90" element={<SampleType90 />} />
      <Route path="/sample/test-select-page" element={<SampleSearchSelectComponent />} />
      <Route path="/sample/sample-component" element={<SampleComponent />} />
      {/* 샘플 E */}

      {/* 개발용 S */}
      {/* <Route path="/dev/login" element={<DevLoginPage />} /> */}
      {/* 개발용 E */}

      {/* 화면 S */}
      <Route path="/member/regist" element={<MemberRegistPage />} />
      <Route path="/mypage" element={<MyPage />} />
      <Route path="/reissue-password" element={<ReIssuePasswordPage />} />
      <Route path="/member/detail" element={<MemberDetailPage />} />
      <Route path="/account-regist" element={<AccountRegistPage />} />
      <Route path="/payment-schedule" element={<MaintenancePage />} />
      <Route path="/payment-schedule-old" element={<MaintenancePage />} />
      <Route path="/abnormal" element={<AbnormalMonitorPage />} />
      <Route path="/faq/list" element={<FaqListPage />} />
      <Route path="/faq/regist" element={<FaqRegistPage />} />
      <Route path="/faq/edit" element={<FaqRegistPage />} />
      <Route path="/qna/list" element={<QnaListPage />} />
      <Route path="/qna/detail" element={<QnaDetailPage />} />
      <Route path="/qna/regist" element={<QnaRegistPage />} />
      <Route path="/qna/edit" element={<QnaRegistPage />} />

      {/* 0405 추가) 관리자 > 지급내역조회 화면 */}
      <Route path="/payment/domestic" element={<MaintenancePage />} />
      <Route path="/payment/overseas" element={<MaintenancePage />} />

      {/* 1216 추가) 관리자 > 지급내역조회_OLD 화면 */}
      <Route path="/payment/domestic-old" element={<MaintenancePage />} />
      <Route path="/payment/overseas-old" element={<MaintenancePage />} />

      {/* 화면 E */}
      <Route element={<ProtectRoute />}>
        {/* 접근제어가 필요한 화면 */}
        {/*  <Route path="/sample" element={<Sample />} /> */}
        {/* <Route path="/accessible" element={<SamplePage />} /> */}
        {/* <Route path="/inaccessible" element={<SamplePage />} /> */}
        {/* TODO 테스트 용으로 머지시 삭제 */}
      </Route>

      {/* 관리자 S */}
      <Route path="/system/admin/code/code" element={<CodeManagementPage />} />
      <Route path="/system/admin/message/message" element={<MessageManagementPage />} />
      <Route path="/system/admin/role/role" element={<RoleManagementPage />} />
      <Route path="/system/admin/role-user/role-user" element={<RoleEmployeeManagementPage />} />
      <Route path="/system/admin/role-menu/role-menu" element={<MenuManagementPage />} />
      <Route path="/system/admin/member/member-list" element={<MemberListPage />} />
      <Route path="/system/admin/email-log/email-log" element={<EmailLogManagementPage />} />
      {/* 관리자 E */}

      {/* 퍼블 S */}
      <Route path="/publ/domestic" element={<Domestic />} />
      <Route path="/publ/overseas" element={<Overseas />} />
      <Route path="/publ/memberlist" element={<AdminList />} />
      <Route path="/publ/memberdetail" element={<AdminDetail />} />
      <Route path="/publ/mypage" element={<MyPage />} />
      <Route path="/publ/signup" element={<Signup />} />
      <Route path="/publ/qnadetail" element={<QnaDetail />} />
      <Route path="/publ/qnawrite" element={<QnaWrite />} />
      <Route path="/publ/qnalist" element={<QnaList />} />
      <Route path="/publ/abnormal" element={<Abnormal />} />
      <Route path="/publ/login" element={<Login />} />
      <Route path="/publ/faqlist" element={<FaqList />} />
      <Route path="/publ/faqwrite" element={<FaqWrite />} />
      <Route path="/publ/payment" element={<Payment />} />
      <Route path="/print-sample" element={<PrintTestPage />} />

      {/* 퍼블 E */}

      {/* <Route
        path="/system/admin/role-department/role-department"
        element={<RoleDepartmentManagementPage />}
        /> */}
      {/* <Route path="/system/admin/api/api" element={<ApiUrlManagementPage />} /> */}
      {/* <Route path={MenuUrl.BbsManagementPage} element={<BbsManagementPage />} />
      <Route path={MenuUrl.BbsPostPage} element={<BbsPostPage />} />
      <Route path={MenuUrl.BbsEditPage} element={<BbsEditPage />} />
     */}

      <Route path="/*" element={<NotFoundPage />} />
      <Route path="/under-construction" element={<UnderConstructionPage />} />
      {/* <Route path="/system/admin/if-log/if-log" element={<IfLogManagementPage />} />
      <Route path="/system/admin/email-log/email-log" element={<EmailLogManagementPage />} />
      <Route path="/system/admin/menu-log/menu-log" element={<MenuLogManagementPage />} />
      <Route path="/system/admin/login-log/login-log" element={<LoginLogManagementPage />} /> */}
      <Route path="/system/admin/sample/notice" element={<NoticeManagementPage />} />
      <Route path="/system/admin/sample/notice-edit" element={<NoticeManagementDetailPage />} />
    </Routes>
  );
};

export default Router;
