/* eslint-disable */
import { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import DialogContent from '@mui/material/DialogContent';
import { GButtonBox, GPopupTitle } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { GButton } from 'components/atom/button';
import Search from 'components/asset/images/Search.svg';
import Img from 'components/asset/images/Confirm.svg';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import { useBankNumberColumnDef } from './hooks/bankNumberColumnDefs';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useLoading } from 'components/process/Loading';
import ModalLayout from 'components/layouts/ModalLayout';
import { DialogActions } from '@mui/material';
import { useCommonModal } from 'hooks/useCommonModal';
import { useTranslation } from 'react-i18next';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import {
  getAdminBankNumberListApi,
  getUserBankNumberListApi,
} from 'apis/vars/account/modal/BankNumberSearchApi';
import useAuthStore from 'stores/useAuthStore';
import EmptyState from 'components/atom/emptyState';
import { getSapIfStdInfo, getSapIfStdInfoG } from 'apis/vars/account/CommonAccount';

type BankSearchModalProps = {
  open: boolean;
  singleSelect?: boolean;
  close: () => void;
  save: (param) => void;
  vendorInfo?: any;
  accnTpCd?: string;
};

export interface bankNumberList {
  bankCode: string; // 개설은행
  bankName: string; // 은행이름
}

const DomesticBankNumber = (props: BankSearchModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;

  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { accessToken } = useAuthStore();
  const [gridData, setGridData] = useState<bankNumberList[]>([]);
  const { defaultColDef, colDefs } = useBankNumberColumnDef();
  const [selectedRow, setSelectedRow] = useState<bankNumberList>({
    bankCode: '',
    bankName: '',
  });
  const [searchParams, setSearchParams] = useState<bankNumberList>({
    bankCode: '',
    bankName: '',
  });

  const [dmstFrgDivsCd, setDmstFrgDivsCd] = useState<string>(props.vendorInfo.dmstFrgDivsCd);

  const getBankNumberList = async () => {
    openLoading(true);
    if (accessToken) {
      if (dmstFrgDivsCd == 'DO') {
        getSapIfStdInfo({
          I_REQ_DIVS: 'BC',
          I_BANK_CODE: searchParams.bankCode,
          I_BANK_NAME: searchParams.bankName,
        }).then((res) => {
          if (props.accnTpCd == 'G') {
            res = res.filter((o) => ['003', '020', '081', '088'].includes(o.bankCode)); //기업, 우리, 하나, 신한
          }
          setGridData(res);
          openLoading(false);
        });
      } else {
        // 미사용
        getSapIfStdInfoG({
          I_REQ_DIVS: 'BC',
          I_BANK_CODE: searchParams.bankCode,
          I_BANK_NAME: searchParams.bankName,
        }).then((res) => {
          setGridData(res);
          openLoading(false);
        });
      }
    } else {
      getAdminBankNumberListApi(searchParams).then((res) => {
        setGridData(res);
        openLoading(false);
      });
    }

    // 추후 일반인 사용자 로직 추가예정
  };

  useEffect(() => {
    if (props.open) {
      getBankNumberList();
    }
  }, [props.open]);

  const handleSearch = () => {
    getBankNumberList();
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedBankCd = selectedNodes.map((node) => node.data.bankCode)[0];
    const selectedBankNm = selectedNodes.map((node) => node.data.bankName)[0];
    // bankCd값을 '선택' 시에 넘겨준다.(추후 수정필요?)
    setSelectedRow({
      bankCode: selectedBankCd,
      bankName: selectedBankNm,
    });
  };

  const gridOptions = {
    columnDefs: colDefs,
    onSelectionChanged: onSelectionChanged,
  };

  const handleClose = () => {
    setSearchParams({
      bankCode: '',
      bankName: '',
    });
    setSelectedRow({
      bankCode: '',
      bankName: '',
    });
    props.close();
  };

  const handleSelect = () => {
    console.log('선택된 행의 bankCd(개설은행) ::: ', selectedRow);
    if (!selectedRow.bankCode) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.msg.noSelRow', '__선택된 행이 없습니다.'),
      });
      return;
    }
    props.save({ ...selectedRow, bankCtryCd: 'KR' });
    handleClose();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.00104', '은행검색')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <ItemWrap>
              <CSearchMolecule
                type="input"
                labelTitle={t('com.label.00105', '은행이름')}
                labelWidth="100px"
                placeholder={t2('com.label.00068', '__입력하세요')}
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
                onChange={(e) => setSearchParams({ ...searchParams, bankName: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
              <CSearchMolecule
                type="input"
                labelTitle={t2('com.label.00483', '__개설은행(Code)')}
                labelWidth="100px"
                placeholder={t2('com.label.00068', '__입력하세요')}
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
                onChange={(e) => setSearchParams({ ...searchParams, bankCode: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
            </ItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt={t('com.label.00116', '조회')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <GContentGrid
          type="radio"
          className="ag-theme-alpine"
          isSortable={false}
          style={{ height: '336px' }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={gridData}
            rowSelection={'single'}
            suppressMovableColumns
            enableCellTextSelection
            onGridReady={(params) => (gridApiRef.current = params.api)}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            noRowsOverlayComponent={EmptyState}
            noRowsOverlayComponentParams={{ type: 'grid' }}
          />
        </GContentGrid>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="medium"
            btnstyled="outline"
            onClick={handleClose}
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          ></GButton>
          <GButton
            txt={t('com.label.00023', '선택')}
            chkImg={Img}
            sizes="medium"
            onClick={handleSelect}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          ></GButton>
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default DomesticBankNumber;

const ItemWrap = styled(SearchItemWrap)`
  display: flex;
  gap: 24px;
`;
