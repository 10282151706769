/** 사용안함(이전 해외 은행 리스트 가져오는 모달) */
import styled from '@emotion/styled';
import { useCommonModal } from 'hooks/useCommonModal';
import { useEffect, useRef, useState } from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { GPopupTitle, GButtonBox } from 'components/layouts/GLayoutBox';
import { DialogTitleItem } from 'components/layouts/GModalPopup';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import { GButton } from 'components/atom/button';
import Save from 'components/asset/images/Confirm.svg';
import Search from 'components/asset/images/Search.svg';
import {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/molecule/SearchMolecule';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { useLoading } from 'components/process/Loading';
import ModalLayout from 'components/layouts/ModalLayout';
import { useFrgnCurrencyColumnDef } from './hooks/frgnCurrencyColumnDefs';
import CSearchMolecule from 'components/vars/common/CSearchMolecule';
import {
  getAdminFrnrBankListApi,
  getUserFrnrBankListApi,
} from 'apis/vars/account/modal/FrgnCurrencySearchApi';
import useAuthStore from 'stores/useAuthStore';
import EmptyState from 'components/atom/emptyState';
import { getSapIfStdInfo, getSapIfStdInfoG } from 'apis/vars/account/CommonAccount';

type FrnrCurrencyModalProps = {
  open: boolean;
  singleSelect?: boolean;
  isKrw: boolean;
  vendorInfo?: any;
  close: () => void;
  save: (param) => void;
};

export interface frnrCurList {
  banks: string; // 국가키
  landx: string; // 국가명
  bankl: string; // 은행키
  banka: string; // 은행명
  brnch: string; // 은행지점
  swift: string; // SWIFT/BIC
  rsltty: string; // 제제국(E일 경우 저장불가)
  bgrup: string; //은행 그룹(은행 네트워크), 은행코드 2자리
}

export interface frnrSearchParam {
  banka: string; // 은행명
  swift: string; // SWIFT/BIC
  iland?: string;
}

const ForeignCurrencyBank = (props: FrnrCurrencyModalProps) => {
  const { t } = useTranslation();
  const t2 = t as (msgId: string, defVal: string) => string;
  const gridApiRef = useRef<any>(null);
  const { openLoading } = useLoading();
  const { openCommonModal } = useCommonModal();
  const { accessToken } = useAuthStore();
  const [gridData, setGridData] = useState<frnrCurList[]>([]);
  const { defaultColDef, colDefs } = useFrgnCurrencyColumnDef();
  const [selectedRow, setSelectedRow] = useState<frnrCurList | null>();
  const [test, setTest] = useState<string>('');
  const [selectedRSLTTY, setSelectedRSLTTY] = useState<string>('');
  const [searchParams, setSearchParams] = useState<frnrSearchParam>({
    banka: '', // 은행명
    swift: '', // SWIFT/BIC
    iland: props.isKrw ? 'KR' : '',
  });

  const [dmstFrgDivsCd, setDmstFrgDivsCd] = useState<string>(props.vendorInfo.dmstFrgDivsCd);

  const getFrgnBankList = async () => {
    openLoading(true);
    if (accessToken) {
      if (dmstFrgDivsCd == 'DO') {
        getSapIfStdInfo({
          I_REQ_DIVS: 'BK',
          I_BANKA: searchParams.banka,
          I_BANK_NAME: searchParams.banka,
          I_SWIFT: searchParams.swift,
          I_LAND: searchParams.iland ?? '',
        }).then((res) => {
          setGridData(res);
          openLoading(false);
        });
      } else {
        getSapIfStdInfoG({
          I_REQ_DIVS: 'BK',
          I_BANKA: searchParams.banka,
          I_BANK_NAME: searchParams.banka,
          I_SWIFT: searchParams.swift,
          I_LAND: searchParams.iland ?? '',
        }).then((res) => {
          setGridData(res);
          openLoading(false);
        });
      }
    } else {
      getAdminFrnrBankListApi(searchParams).then((res) => {
        setGridData(res);
        openLoading(false);
      });
    }
  };

  useEffect(() => {
    if (props.open) {
      // getFrgnBankList();
    }
  }, [props.open]);

  const handleSearch = () => {
    if (!searchParams.banka && !searchParams.swift) {
      openCommonModal({
        modalType: 'alert',
        content: t('com.label.00461', '__검색조건을 입력해주세요'),
      });
      return;
    }
    getFrgnBankList();
  };

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedBkId = selectedNodes.map((node) => node.data.bankl)[0];

    const test = selectedNodes.map((node) => node.data.swift)[0];
    const selectedRSLTTY = selectedNodes.map((node) => node.data.rsltty)[0];

    setSelectedRow(selectedNodes[0]?.data);
    setSelectedRSLTTY(selectedRSLTTY);
    setTest(test);

    console.log('selectedRSLTTY :: ', selectedRSLTTY);
  };

  const gridOptions = {
    columnDefs: colDefs,
    onSelectionChanged: onSelectionChanged,
  };

  const handleClose = () => {
    setSearchParams({
      banka: '',
      swift: '',
    });
    setSelectedRow(null);
    props.close();
  };

  const handleOnSave = () => {
    console.log('선택된 행 :: ', selectedRow);

    if (!selectedRow) {
      return openCommonModal({
        modalType: 'alert',
        content: t('com.msg.noSelRow', '선택된 행이 없습니다.'),
      });
    }
    // 제제국 연결 전 테스트(제제국 연결 성공 시 삭제예정)
    // else if (test === 'ABINAEAAXXX') {
    //   return openCommonModal({
    //     modalType: 'alert',
    //     content: t(
    //       '[사용제한]\n입력된 은행정보는 Sanction리스트에 포함되어있습니다.\n추가적인 내용은 담당자에게 연락하시기 바랍니다.',
    //       '[사용제한]\n입력된 은행정보는 Sanction리스트에 포함되어있습니다.\n추가적인 내용은 담당자에게 연락하시기 바랍니다.'
    //     ),
    //   });
    // }

    // 제제국 메세지 표시
    else if (selectedRSLTTY === 'E') {
      return openCommonModal({
        modalType: 'alert',
        content: (
          <>
            {t('com.label.00462', '__[사용제한]')}
            <br />
            {t('com.label.00463', '__입력된 은행정보는 Sanction리스트에 포함되어있습니다.')}
            <br />
            {t('com.label.00464', '__추가적인 내용은 담당자에게 연락하시기 바랍니다.')}
          </>
        ),
      });
    }

    // BARC* SWIFT코드 선택시
    else if (selectedRow?.swift?.substring(0, 4) === 'BARC') {
      return openCommonModal({
        modalType: 'alert',
        content: t(
          'com.label.00652',
          '__Barclays Bank 계좌등록을 보류하고 있사오니 다른 은행계좌로 등록하여 주시기 바랍니다.'
        ),
      });
    }

    // props.close();
    props.save(selectedRow);
    handleClose();
  };

  return (
    <ModalLayout open={props.open} onClose={handleClose} mMinWidth={1000} mMaxWidth={1000}>
      <DialogTitleItem id="customized-dialog-title" onClose={handleClose}>
        <GPopupTitle title2={t('com.label.00104', '은행검색')} />
      </DialogTitleItem>
      <DialogContent className="popupContent">
        <SearchWrap>
          <UnHiddenSearchWrap>
            <ItemWrap>
              <CSearchMolecule
                type="input"
                labelTitle={t('com.label.00105', '은행이름')}
                labelWidth="100px"
                placeholder={t2('com.label.00068', '입력하세요')}
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
                onChange={(e) => setSearchParams({ ...searchParams, banka: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
              <CSearchMolecule
                type="input"
                placeholder={t2('com.label.00068', '입력하세요')}
                labelTitle="SWIFT/BIC"
                labelWidth="124px"
                searchWidth="calc(50% - 12px)"
                searchValueWidth="100%"
                onChange={(e) => setSearchParams({ ...searchParams, swift: e.target.value })}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') handleSearch();
                }}
              />
            </ItemWrap>
            <GButtonLayout paddingTop="0px" marginBottom="0px">
              <GButtonBoxCum>
                <GButton
                  chkImg={Search}
                  txt={t('com.label.00116', '조회')}
                  sizes="medium"
                  btnstyled="contained"
                  btnColor="normal"
                  hasImg={true}
                  hasTxt={true}
                  onClick={handleSearch}
                ></GButton>
              </GButtonBoxCum>
            </GButtonLayout>
          </UnHiddenSearchWrap>
        </SearchWrap>
        <GContentGrid
          type="radio"
          className="ag-theme-alpine"
          isSortable={false}
          style={{ height: '336px' }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            defaultColDef={defaultColDef}
            columnDefs={colDefs}
            rowData={gridData}
            rowSelection={'single'}
            suppressMovableColumns
            enableCellTextSelection
            onGridReady={(params) => (gridApiRef.current = params.api)}
            tooltipShowDelay={0}
            tooltipHideDelay={2000}
            noRowsOverlayComponent={EmptyState}
            noRowsOverlayComponentParams={{ type: 'grid' }}
          ></AgGridReact>
        </GContentGrid>
      </DialogContent>
      <DialogActions className="popupBottom">
        <GButtonBox>
          <GButton
            txt={t('com.label.00036', '취소')}
            sizes="medium"
            onClick={handleClose}
            btnstyled="outline"
            btnColor="normal"
            hasImg={false}
            hasTxt={true}
          />
          <GButton
            txt={t('com.label.00023', '선택')}
            sizes="medium"
            onClick={handleOnSave}
            chkImg={Save}
            btnstyled="contained"
            btnColor="primary"
            hasImg={true}
            hasTxt={true}
          />
        </GButtonBox>
      </DialogActions>
    </ModalLayout>
  );
};

export default ForeignCurrencyBank;
const ItemWrap = styled(SearchItemWrap)`
  display: flex;
  gap: 24px;
`;
