import { GButton } from 'components/atom/button';
import SearchMolecule, {
  GButtonBoxCum,
  SearchItemWrap,
  SearchWrap,
  UnHiddenSearchWrap,
} from 'components/common/SearchMolecule';
import searchImage from 'components/asset/images/Search.svg';
import RecycleB from 'components/asset/images/RecycleB.svg';
import GButtonLayout from 'components/molecule/GbuttonLayout';
import { SubTitleBtnArea } from 'components/organism/SubTitleBtnArea';
import SubTitleBox from 'components/molecule/SubTitleBox';
import { GContentGrid } from 'components/layouts/GContentGrid';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { useColumnDefsForApprovalList } from './hooks/useMemberList';
import { useLoading } from 'components/process/Loading';
import { useMessageBar } from 'components/process/MessageBar';
import { getMemberList } from 'apis/vars/member/Member';
import { MemberVO } from 'models/vars/member/MemberVO';
import { CommonCode } from 'models/admin/CommonCode';
import { getCommonCodes } from 'apis/admin/CommonCode';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CellClickedEvent } from 'ag-grid-community';
import SearchMoleculeVars from 'components/vars/common/SearchMoleculeVars';
import EmptyState from 'components/atom/emptyState';
import dayjs from 'dayjs';
import { NoticeModal } from 'pages/admin/noticemanagement/NoticeModal';
import { NoticePostDetail } from 'models/admin/Notice';
import { findNoticePopupPost } from 'apis/admin/Notice';
import _ from 'lodash';

const MemberListPage = () => {
  const { t } = useTranslation();
  // MBR_PSG_STAT_CD
  const [statCdList, setStatCdList] = useState<CommonCode[]>([]);

  interface RespMemberVO {
    vdcpCd: string;
    vdcpErpNm: string;
    vdcpNm: string;
    vdcpMgrNm: string;
    vdcpMgrEmal: string;
    soprMgrCopCd: string;
    soprMgrNm: string;
    mbrPsgStatCd: string;
    mbrPsgStatCdNm: string;
    dataInsDtm: string;
    regReqDt: string;
    regReqDtSt?: string;
    regReqDtEnd?: string;
  }

  const InitMemberListGrid: RespMemberVO = {
    vdcpCd: '',
    vdcpErpNm: '',
    vdcpNm: '',
    vdcpMgrNm: '',
    vdcpMgrEmal: '',
    soprMgrCopCd: '',
    soprMgrNm: '',
    mbrPsgStatCd: '',
    mbrPsgStatCdNm: '',
    dataInsDtm: '',
    regReqDt: '',
    regReqDtSt: '',
    regReqDtEnd: '',
  };

  const [noticePopups, setNoticePopups] = useState<NoticePostDetail[]>([]);

  const [memberList, setMemberList] = useState<RespMemberVO[]>([InitMemberListGrid]);

  // const handleDateRangeChange = (newDate, id, key, format) => {
  //   const newParams = {};
  //   if (id === 'end') {
  //     newParams[key + 'End'] = newDate.format(format);

  //     if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
  //       newParams[key + 'St'] = newDate.format(format);

  //     setSearchParams((prev) => {
  //       return {
  //         ...prev,
  //         ...newParams,
  //       };
  //     });
  //   } else if (id === 'start') {
  //     newParams[key + 'St'] = newDate.format(format);

  //     if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
  //       newParams[key + 'End'] = newDate.format(format);

  //     setSearchParams((prev) => {
  //       return {
  //         ...prev,
  //         ...newParams,
  //       };
  //     });
  //   }
  // };

  interface ReqMemberVO {
    mbrPsgStatCd?: string;
    soprMgrNm?: string;
    vdcpErpNm?: string;
    vdcpNm?: string;
    vdcpCd?: string;
    vdcpMgrNm?: string;
    vdcpMgrEmal?: string;
    dataInsDtm?: string;
    regReqDt?: string;
    regReqDtSt?: string;
    regReqDtEnd?: string;
  }

  const [searchParams, setSearchParams] = useState<ReqMemberVO>({
    mbrPsgStatCd: 'ALL',
    soprMgrNm: '',
    vdcpErpNm: '',
    vdcpNm: '',
    vdcpCd: '',
    vdcpMgrNm: '',
    vdcpMgrEmal: '',
    dataInsDtm: '',
    regReqDt: '',
    regReqDtSt: '',
    regReqDtEnd: '',
  });

  // const handleCrtDateChange = (newDate, id) => {
  //   const newParams = {};

  //   if (newDate.isValid()) newParams['regReqDt'] = newDate.format('YYYYMMDD');
  //   else newParams['regReqDt'] = '';

  //   setSearchParams((prev) => {
  //     return {
  //       ...prev,
  //       ...newParams,
  //     };
  //   });
  // };

  useEffect(() => {
    getNoticePopupPosts();
  }, []);

  async function getNoticePopupPosts() {
    const noticePopupPosts = await findNoticePopupPost();
    noticePopupPosts && setNoticePopups(noticePopupPosts);
  }

  const handleCrtDateChange = (newDate, id) => {
    handleDateRangeChange(newDate, id, 'regReqDt', 'YYYYMMDD');
  };

  const handleDateRangeChange = (newDate, id, key, format) => {
    const newParams = {};
    if (id === 'end') {
      newParams[key + 'End'] = newDate.format(format);

      if (searchParams[key + 'St'] === '' || newDate.isBefore(dayjs(searchParams[key + 'St'])))
        newParams[key + 'St'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    } else if (id === 'start') {
      newParams[key + 'St'] = newDate.format(format);

      if (searchParams[key + 'End'] === '' || newDate.isAfter(dayjs(searchParams[key + 'End'])))
        newParams[key + 'End'] = newDate.format(format);

      setSearchParams((prev) => {
        return {
          ...prev,
          ...newParams,
        };
      });
    }
  };

  const gridApiRef = useRef<any>(null);
  const { defaultColDef, colDefs } = useColumnDefsForApprovalList();
  const { openLoading } = useLoading();
  const { openMessageBar } = useMessageBar();
  const navigate = useNavigate();

  /**
   * Init
   */
  useEffect(() => {
    getCommonCodes('MBR_PSG_STAT_CD').then((result: CommonCode[]) => {
      const tmpAll = {
        cmnCd: 'ALL',
        cmnCdNm: t('com.label.00511', '__전체'),
      };
      result.unshift(tmpAll);
      setStatCdList(result);
    });

    getMemberList({ ...searchParams } as MemberVO)
      .then((data) => {
        if (data) {
          const cvtData = data as RespMemberVO[];
          setMemberList(() => {
            return [...cvtData];
          });
        }
      })
      .catch(handleError);
  }, []);

  /**
   * 인풋 컴포넌트 체인지 이벤트
   * @param e
   */
  const handleChangeSearchParams = (e, id) => {
    console.log('아이디 : ', id, e.target.value);
    setSearchParams((prev) => {
      return { ...prev, [id]: e.target.value };
    });
  };

  const handleClickSearchParamInit = () => {
    setSearchParams({
      mbrPsgStatCd: 'ALL',
      soprMgrNm: '',
      vdcpErpNm: '',
      vdcpNm: '',
      vdcpCd: '',
      vdcpMgrNm: '',
      vdcpMgrEmal: '',
      dataInsDtm: '',
      regReqDt: '',
      regReqDtSt: '',
      regReqDtEnd: '',
    });
  };

  const handleCilckSearch = () => {
    console.log('조회', searchParams);

    getMemberList({ ...searchParams } as MemberVO)
      .then((data) => {
        if (data) {
          const data2 = data as RespMemberVO[];
          console.log('data3 :: ', data2);
          setMemberList(() => {
            return [...data2];
          });
        }
      })
      .catch(handleError);
  };

  useEffect(() => {
    console.log('변경', memberList);
  }, [memberList, searchParams]);

  const handleError = (e: Error) => {
    openLoading(false);
    openMessageBar('error', e.message);
  };

  return (
    <>
      <SearchWrap>
        <UnHiddenSearchWrap>
          <SearchItemWrap>
            <SearchMoleculeVars
              labelTitle={t('com.label.00506', '처리상태')}
              searchWidth="50%"
              radio2Id="mbrPsgStatCd"
              type="radio2"
              radio2Value={searchParams.mbrPsgStatCd || ''}
              radio2ValueKey="cmnCd"
              radio2LabelKey="cmnCdNm"
              radio2DefaultValue={searchParams.mbrPsgStatCd}
              // radio2Option={statCdList}
              radio2Option={[
                { cmnCd: 'ALL', cmnCdNm: t('com.label.00511', '__전체') },
                { cmnCd: 'R', cmnCdNm: t('com.label.00299', '__처리중') },
                { cmnCd: 'C', cmnCdNm: t('com.label.00296', '__승인완료') },
              ]}
              onChange={(e) => {
                handleChangeSearchParams(e, 'mbrPsgStatCd');
              }}
            />
            <SearchMolecule
              labelTitle={t('com.label.00095', '담당자')}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.soprMgrNm}
              placeholder={t('com.label.00566', '__담당자를 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'soprMgrNm');
              }}
            />
            <SearchMolecule
              labelTitle={`${t('com.label.00017', '업체명')}(ERP)`}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.vdcpErpNm}
              placeholder={t('com.label.00513', '__업체명(ERP)를 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'vdcpErpNm');
              }}
            />
            <SearchMolecule
              labelTitle={`${t('com.label.00017', '업체명')}(${t('com.label.00514', '거래처')})`}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.vdcpNm}
              placeholder={t('com.label.00512', '__업체명(거래처)를 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'vdcpNm');
              }}
            />
            <SearchMolecule
              labelTitle={t('Vendor ID', 'Vendor ID')}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.vdcpCd}
              placeholder={t('com.label.00016', '__Vendor ID를 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'vdcpCd');
              }}
            />
            <SearchMolecule
              labelTitle={t('com.label.00012', '이름')}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.vdcpMgrNm}
              placeholder={t('com.label.00013', '__이름을 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'vdcpMgrNm');
              }}
            />
            <SearchMolecule
              labelTitle={t('com.label.00090', '이메일')}
              searchWidth="50%"
              searchValueWidth="300px"
              type="input"
              textInputValue={searchParams.vdcpMgrEmal}
              placeholder={t('com.label.00016', '__Vendor ID를 입력하세요')}
              onChange={(e) => {
                handleChangeSearchParams(e, 'vdcpMgrEmal');
              }}
            />
            <SearchMolecule
              type="calendar"
              labelTitle={t('com.label.reqDtm', '__요청일시')}
              calendarDouble={true}
              calendarFormat="YYYY.MM.DD"
              calendarViews={['year', 'month', 'day']}
              calendarMaxWidth="128px"
              startDate={dayjs(searchParams.regReqDtSt)}
              endDate={dayjs(searchParams.regReqDtEnd)}
              onChange={handleCrtDateChange}
              mindate={'2001.01.01'}
              maxdate={`${String(Number(new Date().getFullYear()) + 20)}.01.01`}
            />
          </SearchItemWrap>
          <GButtonLayout marginBottom="0px">
            <GButtonBoxCum>
              <GButton
                chkImg={RecycleB}
                sizes="medium"
                btnstyled="outline"
                btnColor="normal"
                hasImg={true}
                hasTxt={false}
                onClick={handleClickSearchParamInit}
              ></GButton>
              <GButton
                chkImg={searchImage}
                txt={t('com.label.00116', '조회')}
                sizes="medium"
                btnstyled="contained"
                btnColor="normal"
                hasImg={true}
                hasTxt={true}
                onClick={handleCilckSearch}
              ></GButton>
            </GButtonBoxCum>
          </GButtonLayout>
        </UnHiddenSearchWrap>
      </SearchWrap>

      <SubTitleBtnArea>
        <SubTitleBox
          content={t('com.label.00423', '__회원가입 신청 목록')}
          showSubTitle={true}
          isVisibleComment={false}
          isVisibleInfo={false}
        />
      </SubTitleBtnArea>
      <GContentGrid
        type="none"
        className="ag-theme-alpine"
        style={{ flex: 1, marginBottom: '8px' }}
        isSortable={false}
      >
        <AgGridReact
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          rowData={memberList}
          rowSelection={'single'}
          enableCellTextSelection
          onGridReady={(params) => (gridApiRef.current = params.api)}
          tooltipShowDelay={0}
          onCellClicked={(e: CellClickedEvent) => {
            // navigate('/cims/clm/clmmng/detail', { state: { clmId: e.data.clmId } });
            navigate('/member/detail', { state: { vdcpCd: e.data.vdcpCd } });
          }}
          noRowsOverlayComponent={EmptyState}
          noRowsOverlayComponentParams={{ type: 'grid' }}
        ></AgGridReact>
      </GContentGrid>
      {noticePopups
        ?.filter((item) => item.bbmClsfCd === 'EMPLOYEE')
        .map((notice) => {
          return (
            <NoticeModal
              key={notice.bbmNo}
              bbmNo={notice.bbmNo}
              bbmTitNm={notice.bbmTitNm}
              bbmCtn={notice.bbmCtn}
              poupEpsNuseDdn={Number(notice.poupEpsNuseDdn)}
            />
          );
        })}
    </>
  );
};

export default MemberListPage;
